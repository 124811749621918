const MAX_INT = 9007199254740992;

export const sortCategories = categories =>
  categories.slice().sort((categoryA, categoryB) => {
    const positionA = categoryA.position || MAX_INT;
    const positionB = categoryB.position || MAX_INT;
    if (positionA > positionB) {
      return 1;
    } else if (positionB > positionA) {
      return -1;
    }
    return 0;
  });
