import React from 'react';
import { inject, observer, PropTypes } from 'mobx-react';
import TextField from '@material-ui/core/TextField';
import FormDialog from 'Components/Dialog/FormDialog';
import Loader from 'Components/Progress/Loader';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';

class MerchantTypeForm extends React.Component {
  static propTypes = {
    MerchantTypesStore: PropTypes.observableObject,
    CategoriesStore: PropTypes.observableObject,
  };
  componentWillMount() {
    this.props.CategoriesStore.loadTaxonomies();
  }
  handleFormSubmit = (e) => {
    e.preventDefault();
    this.submit();
  };
  submit = () => {
    this.props.MerchantTypesStore.submitChanges();
  };
  cancel = () => {
    this.props.MerchantTypesStore.closeEditor();
  };
  makeChangeHandler = (name) => {
    return (e) => {
      this.props.MerchantTypesStore.registerChange(name, e.target.value);
    };
  };
  render() {
    const {
      merchantTypeId,
      isBusy,
      values,
      errors = {},
    } = this.props.MerchantTypesStore.merchantTypeEditor;
    const {
      CategoriesStore: { isLoadingTaxonomies, taxonomies },
    } = this.props;
    const title = merchantTypeId ? 'Update user type' : 'Add new user type';
    return (
      <Loader isBusy={isLoadingTaxonomies}>
        <FormDialog
          title={title}
          isBusy={isBusy}
          handleCancel={this.cancel}
          handleSubmit={this.submit}
        >
          <form onSubmit={this.handleFormSubmit}>
            <TextField
              label="Name"
              placeholder="User type name"
              value={values.name || ''}
              error={!!errors.name}
              helperText={errors.name}
              fullWidth
              margin="normal"
              onChange={this.makeChangeHandler('name')}
            />
            <FormControl fullWidth>
              <InputLabel>Categories</InputLabel>
              <Select
                fullWidth
                style={{ marginTop: 20 }}
                placeholder="Select categories available for user type"
                value={values.taxonomyId || ''}
                onChange={this.makeChangeHandler('taxonomyId')}
              >
                {taxonomies.map(({ id, title }) => (
                  <MenuItem value={id} key={id}>
                    <em>{title}</em>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </form>
        </FormDialog>
      </Loader>
    );
  }
}

export default inject(
  'MerchantTypesStore',
  'CategoriesStore'
)(observer(MerchantTypeForm));
